.mc__title {
    color: #fff;
    text-align: center;
    letter-spacing: 5px;
    font-weight: 200;
}

.mc__form-container {
    width: 100%;
    margin: 0 auto;
    transition: height .2s ease;
}

.mc__form {
    display: grid;
    grid-template-rows: min-content min-content;
    grid-row-gap: 40px;
    justify-content: stretch;
    align-items: center;
    transition: height .2s ease;
}

.mc__field-container {
    display: grid;
    grid-row-gap: 30px;
}

.g__justify-self-center {
    justify-self: center;
}