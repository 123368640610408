.primaryBtn {
    border-style: none;
    color: #fff;
    text-align: center;
    letter-spacing: 5px;
    font-weight: 200;
    box-shadow: 0 4px 23px rgba(0, 0, 0, 0.45);

    cursor: pointer;
    padding: 10px 20px;
    transition: 0.3s ease-in-out;
    -webkit-appearance: none;
    -webkit-border-radius: 0;

    &--normal {
        padding: 8px 18px;
        font-size: 16px;

        /*@include m.smMinBreakPoint {*/
        /*    max-width: max-content;*/
        /*    padding: 4px 18px;*/
        /*}*/
    }

    &--big {
        padding: 10px 30px;
        font-size: 20px;

        /*@include m.smMinBreakPoint {*/
        /*    max-width: max-content;*/
        /*}*/
    }

    &:hover {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.45);
        transform: scale(0.98);
    }

    /*&--grid {*/
    /*    display: grid;*/
    /*    grid-template-columns: repeat(2, max-content);*/
    /*    grid-column-gap: 10px;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    text-decoration: none;*/
    /*}*/

}