.inputField__label {
    display: grid;
    grid-row-gap: 10px;
    font-size: 16px;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    color: #fff;
    font-weight: 200;
}

.inputField__field {
    border: none;
    font-size: 16px;
    padding: 16px 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    font-weight: bold;
}

::placeholder { /* Firefox */
    font-weight: normal;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-weight: normal;
}

::-ms-input-placeholder { /* Microsoft Edge */
    font-weight: normal;
}


input[disabled] {
    cursor: default;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.45);

    &:hover {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.45);
        transform: scale(1);
    }
}